import React, { useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import Intro from "../components/page-intro"
import Helmet from "react-helmet"
import Img from "gatsby-image"
import { motion } from 'framer-motion'
import Swiper from "swiper"
import 'swiper/swiper-bundle.min.css'


const duration = 0.25

const container = {
  visible: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
      delayChildren: duration,
    },
  },
}
const item = {
  hidden: { x: 0, y: 20, opacity: 0 },
  visible: {
    y: 0,
    x: 0,
    opacity: 1,
  },
}


const HeartChurch = () => {
      const data = useStaticQuery(graphql`
        query {
        allDirectory {
          edges {
            node {
              id
            }
          }
        }
        hcGuidelines1: file(relativePath: {eq: "hc-guidelines-1.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcGuidelines2: file(relativePath: {eq: "hc-guidelines-2.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcGuidelines3: file(relativePath: {eq: "hc-guidelines-3.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcPosterFaith: file(relativePath: {eq: "hc-poster-faith.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcHS: file(relativePath: {eq: "hc-h+s.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcPoster1: file(relativePath: {eq: "hc-poster-1.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcPoster2: file(relativePath: {eq: "hc-poster-2.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcPoster3: file(relativePath: {eq: "hc-poster-3.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcScreen1: file(relativePath: {eq: "hc-screen-1.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcScreen2: file(relativePath: {eq: "hc-screen-2.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcLanyard1: file(relativePath: {eq: "hc-lanyard-1.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcLanyard2: file(relativePath: {eq: "hc-lanyard-2.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcLanyard3: file(relativePath: {eq: "hc-lanyard-3.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcLanyard4: file(relativePath: {eq: "hc-lanyard-4.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcLanyard5: file(relativePath: {eq: "hc-lanyard-5.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcCampaign1: file(relativePath: {eq: "hc-campaign-1.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcCampaign2: file(relativePath: {eq: "hc-campaign-2.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcCampaign3: file(relativePath: {eq: "hc-campaign-3.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcCampaign4: file(relativePath: {eq: "hc-campaign-4.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcCampaign5: file(relativePath: {eq: "hc-campaign-5.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcWeb1: file(relativePath: {eq: "hc-web-1.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcWeb2: file(relativePath: {eq: "hc-web-2.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcWeb3: file(relativePath: {eq: "hc-web-3.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hcSocial: file(relativePath: {eq: "social-heart-church.jpg"}) {
          childImageSharp {
            fixed(width: 1200, height: 630, quality: 100) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    `)

  const swiper = useRef(null)

  useEffect(()=>{
    setTimeout(function(){
      swiper.current = new Swiper('.swiper-container',{
        slidesPerView: 1,
        spaceBetween: 50,
        freeMode: false,
      })
     },1000)
    
  },[])

  return (
    <>
      <SEO 
        title="Heart Church - Brand Development" 
        description="Brand development, website and digital design for Heart Church, a forward-thinking city church in Nottingham." 
        image={data.hcSocial.childImageSharp.fixed}
      />
      <Helmet
        htmlAttributes={{
          class: 'heart-church bg-black text-white',
        }}
      />
    
      <motion.section
        variants={container}
        initial="hidden" 
        animate="visible"
        className="container"
      >

        <Intro 
          borderColor="border-white border-opacity-20"
          introHeader="Heart Church —"
          introSubHeader="Brand development"
          introSummary="Heart Church, Nottingham, transitioned leadership in 2014 and with that came the requirement for an identity that better represented the organisation they wanted to become — a creative, dynamic and culturally relevant church.">
        </Intro>
      
        <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl">
          <div className="border-b"></div>
          <div className="border-b border-l  p-6 md:p-10 overflow-hidden">

            <motion.div 
              variants={container}
              transition="easeInOut"
              className="intro-bottom flex lg:flex-wrap flex-row md:space-x-1 lg:space-x-1 items-center justify-center">

              <motion.div 
                variants={item}
                transition="easeInOut"
                className="hc-logo flex-grow h-8 md:h-16">
                  <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="97.854" height="64.969" viewBox="0 0 97.854 64.969">
                    <path id="Path_8" data-name="Path 8" d="M194.845,206.011l-17.232-13.192L161.8,205.239,145.977,193.1l-15.955,12.148-15.69-12.152L97.1,206.279l-.055,20.18,33.016,31.329,15.768-15.009,15.785,15.009,32.754-31.1.531-.5Zm-34.379,1.6v17.522l-14.635,13.931L131.362,225.3v-17.69l14.611-11.124Zm-30.4,46.462L99.746,225.3l.048-17.693,14.528-11.117L128.669,207.6v18.854l15.209,14.461Zm31.556,0-13.832-13.153,15.374-14.635V207.6l14.475-11.372,14.519,11.117.05,17.689Z" transform="translate(-97.049 -192.819)" fill="#fff"/>
                  </svg>
              </motion.div>
              <motion.div 
                variants={item}
                transition="easeInOut"
                className="hc-logo flex-grow h-8 md:h-16">
                  <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="67.379" height="66.168" viewBox="0 0 67.379 66.168">
                    <g id="Group_8" data-name="Group 8" transform="translate(-51.767 -45.585)">
                      <path id="Path_5" data-name="Path 5" d="M152.675,101.069c-1.213,1.971-2.472,4.118-3.941,6.311-.708,1.054-3.134,5.9-4.586,5.126s.563-3.281,1.021-4.152a25.079,25.079,0,0,0,1.958-4.766c-1.051.679-1.588,1.923-2.372,2.951-1.563,2.043-3.247,3.917-4.89,5.968-.756.944-1.742,2.56-2.882,2.054s-.257-2.159.212-2.9c1.9-2.984,3.983-5.706,5.408-8.4-2.285,2.716-4.983,5.6-7.454,8.135-.788.806-1.78,2.35-3.045,1.519a.854.854,0,0,1-.338-.414,4.5,4.5,0,0,1-.7.661c-2.786,2-6.145,2.518-8.354,4.936,3.872,1.606,6.943-.979,10.126.749.089,1.282-1.321,1.691-2.046,1.859-3,.708-6.16.187-8.857,1.24-2.329.911-4.556,3.736-7.087,5.242-.714.423-1.456.772-2.173,1.156l13,12.331,33.725-32.017.108-.1-.053-19.319C157.074,92.2,155.128,97.082,152.675,101.069Z" transform="translate(-40.355 -28.926)" fill="#fff"/>
                      <path id="Path_6" data-name="Path 6" d="M65.763,92.366c.376-1.162.179-3.243.626-5.126a19.953,19.953,0,0,0,.809-5.211c-.1-1.772-1.439-3.04-1.117-4.531,4.253-.764,3.386,4.962,5.3,6.5,2.038-.72,3.182-3.8,4.53-6.623.778-1.637,2.808-7.816,4.7-6.254.866.719-.975,4.44-1.461,5.74-.893,2.386-1.951,4.474-1.005,5.754a39.683,39.683,0,0,1,4.338-4.4c.818-.784,3.367-4.165,4.82-3.613,1.957.745-.211,2.821-1,3.615s-1.543,1.477-2.145,2.132c-1.672,1.819-3.1,3.14-3.759,4.913,2.345.345,3.635-.739,5.684-1.773.668-.337,1.528-.851,2.625-1.346.533-.242,2.206-1.342,3.022-.746a1.02,1.02,0,0,1,.212.212c.238-.381.487-.706.625-.889,2.345-3.109,4.782-5.435,6.661-8.9-.936-.036-1.67.92-2.481,1.6s-1.74,1.327-2.529,2.062c-1.116,1.039-3.463,4.563-5.153,3.733-2.017-.99,1.567-4.238,2.281-5.007,1.178-1.266,2.141-2.283,3.127-3.393,2.268-2.55,4.377-4.9,5.362-7.939-1.663-.186-3.559.8-5.4.788-1.642-.007-4.228-.731-3.9-2.714,4.291.164,7.226-2.852,10.831-4.3,3.235-1.3,6.892-1.688,9.848-3.377L101.6,45.585,85.415,59.562l-.215.027L69.311,45.872,51.822,59.86l-.055,20.063L65.5,92.954A3.266,3.266,0,0,0,65.763,92.366Z" fill="#fff"/>
                    </g>
                  </svg>
              </motion.div>
              <motion.div 
                variants={item}
                transition="easeInOut"
                className="hc-logo flex-grow h-8 md:h-16">
                  <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="65.85" height="64.969" viewBox="0 0 65.85 64.969">
                    <path id="HeartChurch-Logo-Icon-White-Small-Scale" d="M114.124,71.381,96.908,57.609l-.089-.069L81.1,71.114l-15.4-13.3-17.257,13.8-.05.041L48.33,91.341V91.4l32.788,31.109,33.019-31.347.043-.041ZM85.709,116.041,81.1,120.407l-5.7-5.412Zm1.7-17.955-.648,14.07-2.808-6.1Zm-1.242,16.477-10.8-1.1,7.43-6.241ZM82.792,97.526l4.191-2.376-3.5,8.127Zm29.867-8.832L105.2,81.3l7.421-7.531Zm-.454,1.689L99.956,96.068l4.437-13.425Zm-14.751-30.4,14.258,11.407L97.671,73.3Zm13.826,13-7.159,7.255-5.574-5.531ZM88.094,83.313l8.814-8.1,5.731,5.677Zm21.735,9.845L90.8,111.216,99.226,98.09Zm-12.275,4.71-9.268,14.457.838-17.637ZM95.61,95.519,89,93.028,87,87.04ZM87.093,85.011l15.79-2.623L98.347,96.1ZM85.771,83.38,82.284,72.967,95.214,74.7ZM82.1,77.256l5.31,15.864L82.7,95.774Zm.821-5.727L95.932,60.294l.216,13.012ZM73.63,102.7l7.721-4.435.8,6.7Zm7.816,3.651-7.462,6.265-1.4-8.626Zm-10.4-2.376,1.3,8.065-6.57-6.185ZM66.356,98.47l-2.376,5.684L52.335,93.106ZM64.17,91.125l-13.751-.8,5.371-7.067Zm-9.4-9.026-4.917,6.466.039-13.943ZM65.71,73.071l.553-12.759L79.115,71.405Zm.661,1.432,14.042-1.728.73,21.93ZM77.979,96.539l-9.951.594-.8-4.036Zm.771,1.478-6.93,3.975-2.852-3.4ZM66.436,96.891,53.753,92.032l11.895.693Zm-.585-20.523,14.021,19.18L67,91.421ZM50.987,71.513l13.755-11-.551,12.642Zm13.284,4.163,1.106,14.507-8.555-8.03Zm-8.425,5.314-5.211-8,12.614,1.566Zm14.513,21.627-4.8,1.713,2.07-4.969Z" transform="translate(-48.33 -57.54)" fill="#fff"/>
                  </svg>
              </motion.div>
              <motion.div 
                variants={item}
                transition="easeInOut"
                className="hc-logo flex-grow h-8 md:h-16">
                  <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="63.581" height="70.395" viewBox="0 0 63.581 70.395">
                    <path id="Path_7" data-name="Path 7" d="M195.082,69.761l-15.5,11.8L164.333,69.757,147.9,82.336V110l31.725,30.154,31.856-30.322V82.337Zm14.375,39.2-29.839,28.4-29.754-28.235v-5.595l29.757,28.285,29.836-28.4Zm0-8.332-29.839,28.4L149.864,100.8V91.667l14.463-11.032L179.568,92.44l15.51-11.809,14.379,11.034Zm0-11.514-14.375-11.03-15.5,11.805L164.333,78.086,149.864,89.16V83.335L164.327,72.3l15.241,11.8L195.078,72.3l14.379,11.032Z" transform="translate(-147.896 -69.757)" fill="#fff"/>
                  </svg>
              </motion.div>
              <motion.div 
                variants={item}
                transition="easeInOut"
                className="hc-logo flex-grow h-8 md:h-16">
                  <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="70.14" height="70.395" viewBox="0 0 70.14 70.395">
                    <path id="Path_9" data-name="Path 9" d="M580.215,74.671l0-1.021L560.935,58.23l-15.9,13.721L529.47,58.51,510.192,73.93l-.06,21.551,34.932,33.145L580.272,95.2ZM561.024,63.007l14.212,11.369L561.363,85.705,547.881,74.354Zm-15.961,60.552L515,95.033l12.415-8.656,28.371,27Zm13.392-12.714L527.943,81.805l-.13-.186-13.994,9.759.043-15.679L529.38,63.288l15.659,13.518.024-.019,16.184,13.625.065-.076.076.093L576.55,78.049l.042,15.577Z" transform="translate(-510.132 -58.23)" fill="#fff"/>
                  </svg>
              </motion.div>

            </motion.div>

          </div>
          <div className="border-b border-l"></div>
        </div>
           

      </motion.section>


      <section className="content">
      <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl">
        <div className="border-b"></div>
        <div className="border-b border-l p-6 md:p-10 overflow-hidden">
            
            <div className="w-10/12 md:w-3/4 m-0">
    
            <div className="swiper-container">

              <div className="swiper-wrapper">

                  <div className="swiper-slide">
                    <Img backgroundColor="#F1EBE8" fluid={data.hcGuidelines1.childImageSharp.fluid}  />
                  </div>

                  <div className="swiper-slide">
                    <Img backgroundColor="#F1EBE8" fluid={data.hcGuidelines2.childImageSharp.fluid}  />
                  </div>

                  <div className="swiper-slide">
                    <Img backgroundColor="#F1EBE8" fluid={data.hcGuidelines3.childImageSharp.fluid}  />
                  </div>

               </div>

            </div>

            </div>


            <div className="md:grid md:grid-cols-12 py-20 pt-10 xl:px-48 xl:py-32">
              
              <div className="col-start-1 col-span-5">
                <h2>Brand guidelines</h2>
              </div>

              <div className="col-start-6 col-span-7">
                <p>Following the brand development, a simple brand guidelines was created to serve the team of creative volunteers who produce content week in, week out. The heart logo mark was adapted for use against the many different departments and ministries of the church.</p>
              </div>

            </div>
         
        </div>
        <div className="border-b border-l"></div>
      </div>

      <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl">
        <div className="border-b"></div>
        <div className="border-b border-l p-6 md:p-10">


          <div className="md:flex md:flex-row md:flex-nowrap md:flex-shrink">

            <div className="md:w-2/3 md:flex-col md:pr-10">
              <Img backgroundColor="#F1EBE8"  fluid={data.hcPosterFaith.childImageSharp.fluid}  />
            </div>

            <div className="flex flex-col-reverse flex-nowrap md:w-1/3 md:flex-col md:self-end ">
              <div className="mob:flex mob:flex-col">
                <h2>Sample artwork</h2>
                <p>Example content was created to show how the brand could flex across the wide variety of communications that went out to a diverse audience.</p>
                <p>From youth events to more corporate gatherings, the brand had a broad range of elements to help achieve a varied tone.</p>
              </div>
              <div className="mob:flex mob:flex-col mob:mb-10 mob:w-full ">
                <Img backgroundColor="#F1EBE8"  fluid={data.hcHS.childImageSharp.fluid} className="image-scale-in mt-10" />
               </div>
            </div>

          </div>

          <hr className="md:hidden mt-10"/>

          <div className="md:flex flex-row flex-nowrap flex-shrink pt-12 md:pt-12 lg:pt-20 md:px-24  ">

            <div className="w-full md:w-1/3 md:flex-col md:pr-8">
              <Img backgroundColor="#F1EBE8"  fluid={data.hcPoster1.childImageSharp.fluid} className="image-scale-in mb-10" />
            </div>

            <div className="w-full md:w-1/3 md:flex-col md:pr-2 md:pl-4">
              <Img backgroundColor="#F1EBE8"  fluid={data.hcPoster2.childImageSharp.fluid} className="image-scale-in mb-10" />
            </div>

            <div className="w-full md:w-1/3 md:flex-col md:pl-8">
              <Img backgroundColor="#F1EBE8"  fluid={data.hcPoster3.childImageSharp.fluid} className="image-scale-in mb-10" />
            </div>

           
          </div>

        </div>
        <div className="border-b border-l"></div>
      </div>


     


      <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl">
        <div className="border-b"></div>
        <div className="border-b border-l p-6 md:p-10 lg:pb-40 overflow-hidden">

          <div className="w-10/12 md:w-3/4 m-0">
            
            <div className="swiper-container">
              <div className="swiper-wrapper">

                  <div className="swiper-slide">
                    <Img backgroundColor="#F1EBE8"  fluid={data.hcScreen1.childImageSharp.fluid}  />
                  </div>

                  <div className="swiper-slide">
                    <Img backgroundColor="#F1EBE8"  fluid={data.hcScreen2.childImageSharp.fluid}  />
                  </div>
                
               </div>
            </div> 

          </div>

          <div className="grid grid-cols-12 gap-10 py-10 pt-20">
            <div className="col-start-1 col-span-12 md:col-start-2 md:col-span-10 lg:col-start-2 lg:col-span-5">
              <h2>Screen content</h2>
              <p>Simple slides were created for the 10 metre LED screen – ensuring legibility across the 600+ congregation.</p>
            </div>
          </div>

           </div>
        <div className="border-b border-l"></div>
      </div>

      <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl">
        <div className="border-b"></div>
        <div className="border-b border-l p-6 md:p-10 overflow-hidden">


          <div className="grid grid-cols-2 lg:grid-cols-5 gap-4 md:gap-8 lg:gap-10 ">
            <div className="col-start-1 col-span-1">
              <Img backgroundColor="#7BB8DC"  fluid={data.hcLanyard1.childImageSharp.fluid}  />
            </div>
            <div className="col-start-2 col-span-1">
              <Img backgroundColor="#B79B98"  fluid={data.hcLanyard2.childImageSharp.fluid}  />
            </div>
            <div className="col-start-1 lg:col-start-3 col-span-1">
              <Img backgroundColor="#E40134"  fluid={data.hcLanyard3.childImageSharp.fluid}  />
            </div>
            <div className="col-start-2 lg:col-start-4 col-span-1">
              <Img backgroundColor="#FBB900"  fluid={data.hcLanyard4.childImageSharp.fluid}  />
            </div>
            <div className="col-start-1 lg:col-start-5 col-span-1">
              <Img backgroundColor="##0B956D"  fluid={data.hcLanyard5.childImageSharp.fluid}  />
            </div>
          </div>

          <div className="grid grid-cols-12 gap-10 py-10 pt-20 lg:pb-40">
            <div className="col-start-1 col-span-12 md:col-start-2 md:col-span-10 lg:col-start-2 lg:col-span-5">
              <h2>Lanyards</h2>
              <p>Lanyard designs served to help identify the many teams of volunteers that helped Heart Church run efficient services each Sunday. From welcoming people at the door, to dealing with safeguarding issues.</p>
            </div>
          </div>

        </div>
        <div className="border-b border-l"></div>
      </div>  

      <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl">
        <div className="border-b"></div>
        <div className="border-b border-l p-6 md:p-10 overflow-hidden">

          <div className="md:grid grid-cols-12 gap-10 pt-10 xl:px-48 py-20 xl:py-32">
            
            <div className="col-start-1 col-span-4">
              <h2>Campaign creative direction</h2>
            </div>
          
            <div className="col-start-7 col-span-6">
              <p className="mb-6">Art direction was provided across numerous campaigns, creating toolkits for the wider creative team to use in order to create a wealth of campaign assets. Here are some initial visuals for Vision Sunday 2018 – "Spread Out! Think Big!". </p>
            </div>

          </div>
          


          <div>
            <div className="mb-6">
              <Img backgroundColor="#131181"  fluid={data.hcCampaign1.childImageSharp.fluid}  />
            </div>
            <div className="">
              <div className="mb-6">
                <Img backgroundColor="##151818"  fluid={data.hcCampaign2.childImageSharp.fluid}  />
              </div>
              <div className="mb-6">
                <Img backgroundColor="#9C8D6E"  fluid={data.hcCampaign3.childImageSharp.fluid}  />
              </div>
              <div className="mb-6">
                <Img backgroundColor="#DCA3B6"  fluid={data.hcCampaign4.childImageSharp.fluid}  />
              </div>
              <div className="mb-6">
                <Img backgroundColor="##B1BCC2"  fluid={data.hcCampaign5.childImageSharp.fluid}  />
              </div>
            </div>
            
          </div>

        </div>
        <div className="border-b border-l"></div>
      </div>

      <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl">
        <div className="border-b"></div>
        <div className="border-b border-l p-6 md:p-10 overflow-hidden">

          <div className="md:grid grid-cols-12 gap-10 pt-10 xl:px-48 py-20 xl:py-32">
            
            <div className="col-start-1 col-span-4">
              <h2>Web design</h2>
            </div>
          
            <div className="col-start-7 col-span-6">
              <p className="mb-6">Following the development of the brand, I worked on designing the organisations corporate website, from concept through to development.</p>
              <p className="mb-6">The website needed to clearly communicate the various events that Heart Church run week-to-week whilst capturing the culture of the church. A simple content management system was provided using <a href="https://grabaperch.com" target="_blank" rel="noreferrer">Perch</a> allowing the Comms Team to keep everything up-to-date.</p>
            </div>

          </div>

          <div className=" ">
            
            <div className="mb-6 md:mb-10">
              <Img backgroundColor="#59507D" fluid={data.hcWeb1.childImageSharp.fluid}  />
            </div>

          </div>

          <div>
            <div className="md:grid grid-cols-2 gap-4  md:gap-10">
              <div className="col-start-1 col-span-1">
                <Img backgroundColor="#A5AEE5"  fluid={data.hcWeb2.childImageSharp.fluid} className="image-scale-in mob:mb-6" />
              </div>
              <div className="col-start-2 col-span-1">
                <Img backgroundColor="#AD90E4" fluid={data.hcWeb3.childImageSharp.fluid}  />
              </div>
            </div>
          </div>

         
        </div>
        <div className="border-b border-l"></div>
      </div>
      </section>
      
    </>
  )
}



export default HeartChurch

